import React,{Fragment, useEffect, useState} from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";




const Home = React.memo(React.lazy(()=>(import('../common/Home'))));

export default function Header() {
  return (
    <Router>
        <Switch>
          <Route path='/'>
            <Home />
          </Route>
        </Switch>
        
    </Router>
    
  )
}
